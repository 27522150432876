import React, { useEffect, useState } from "react";
import TransportList from "../TransportList/TransportList";
import CustomMapContainer from "../Map/CustomMapContainer";
import "./Transport.scss";
import {
  CanIdentifierDTO,
  CriterionParameter,
  FunctionDTO,
  FunctionGroup,
  FunctionStatus,
  ModuleConnectionDTO,
  ModuleDTO,
  VehicleDTO,
  VehicleType,
} from "../../api/auth/apiClient";
import { useIntl } from "react-intl";
import TransportEdit from "../TransportEdit/TransportEdit";
import { MapCenterType, TransportTypeProps } from "./TransportType";
import { mapClient } from "../../api/auth/AxiosInstanse";
import { NotificationType } from "../Notification/notificationTypes";
import CreateTransport from "../CreateTransport/CreateTransport";
import {
  HttpTransportType,
  HubConnectionBuilder,
  HubConnectionState,
  JsonHubProtocol,
  LogLevel,
} from "@microsoft/signalr";

type messageDTO = {
  uvi: string;
  coordinatesDTO: {
    longitude: number;
    latitude: number;
  };
  engineHours: undefined;
  messageDate: Date;
  recordDate: Date;
  number: number;
};

function Transport(props: TransportTypeProps) {
  const [carInfo, setCarInfo] = useState<Array<VehicleDTO>>([]);
  const [searchTransport, setSearchTransport] = useState(carInfo);

  const intl = useIntl();
  const [mapCenter, setMapCenter] = useState<MapCenterType>({
    latitudeCenter: 55.4424,
    longitudeCenter: 37.3636,
    zoom: 15,
  });
  const [activeTransport, setActiveTransport] = useState<VehicleDTO>();
  const [showCreateTransport, setShowCreateTransport] = useState(false);
  const [transportActiveClick, setActiveClick] = useState(false);
  const [typeTransport, setTypeTransport] = useState<Array<VehicleType>>([]);
  const [functions, setFunctions] = useState<Array<FunctionDTO>>([]);
  const [filterFunctions, setFilterFunctions] = useState(functions);
  const [functionGroups, setFunctionGroups] = useState<Array<FunctionGroup>>(
    []
  );
  const [statusFunctions, setStatusFunctions] = useState<Array<FunctionStatus>>(
    []
  );
  const [criteriaParameters, setCriteriaParameters] = useState<
    Array<CriterionParameter>
  >([]);
  const [showTable, setShowTable] = useState(false);
  const [selectedRow, setSelectedRow] = useState<FunctionDTO>(
    {} as FunctionDTO
  );
  const [canIdentifiers, setCanIdentifiers] = useState<Array<CanIdentifierDTO>>(
    []
  );
  const [isStartCoordinatesUpdate, setStartCoordinatesUpdate] =
    useState<boolean>(false);

  const [module, setModule] = useState<ModuleDTO>();
  const [showModuleSettings, setShowModuleSettings] = useState(false);
  const [logModule, setLogModule] = useState<Array<ModuleConnectionDTO>>();

  async function getTransport() {
    try {
      const response = await mapClient.getVehicles();
      const sortedTransport =
        response.data?.sort((a, b) =>
          (a.creationDate ?? 0) > (b.creationDate ?? 0) ? -1 : 1
        ) ?? [];
      setCarInfo(sortedTransport);
      setSearchTransport(sortedTransport);

      const typeTransport = await mapClient.getVehicleTypes();
      setTypeTransport(typeTransport.data ?? []);

      const functionStatusResult = await mapClient.getFunctionStatuses();
      setStatusFunctions(functionStatusResult.data ?? []);

      const criteriaParametersResult = await mapClient.getCriteriaParameters();
      setCriteriaParameters(criteriaParametersResult.data ?? []);

      setStartCoordinatesUpdate(true);
    } catch (error) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "data_receiving_error" }),
      });
    }
  }

  useEffect(() => {
    getTransport();
  }, []);

  useEffect(() => {
    if (isStartCoordinatesUpdate) {
      const protocol = new JsonHubProtocol();
      const connection = new HubConnectionBuilder()
        .withUrl("https://platform.sayoratec.com:160/coordinates", {
          accessTokenFactory: () => localStorage.getItem("token") ?? "",
          withCredentials: false,
          transport: HttpTransportType.WebSockets,
          logMessageContent: true,
          logger: LogLevel.Trace,
          skipNegotiation: false,
        })
        .withAutomaticReconnect()
        .withHubProtocol(protocol)
        .configureLogging(LogLevel.Information)
        .configureLogging(LogLevel.Trace)
        .build();

      try {
        connection
          .start()
          .then(() => {
            console.assert(connection.state === HubConnectionState.Connected);
            console.log("SignalR Connected.");

            connection.on("SendCoordinatesAsync", (message: messageDTO) => {
              setCarInfo((prevCarInfo) => {
                const carIndex = prevCarInfo.findIndex(
                  (car) => car.uvi === message.uvi
                );

                if (carIndex === -1) {
                  return [
                    ...prevCarInfo,
                    {
                      uvi: message.uvi,
                      coordinates: {
                        longitude: message.coordinatesDTO.longitude,
                        latitude: message.coordinatesDTO.latitude,
                        init: () => console.log("Coordinates initialized"),
                        toJSON: () => ({
                          longitude: message.coordinatesDTO.longitude,
                          latitude: message.coordinatesDTO.latitude,
                        }),
                      },
                      name: "Unknown",
                      init: () => console.log("Vehicle initialized"),
                      toJSON: () => ({}),
                    } as VehicleDTO,
                  ];
                } else {
                  const car = prevCarInfo[carIndex];
                  const updatedCarInfo = [...prevCarInfo];

                  updatedCarInfo[carIndex] = {
                    ...car,
                    coordinates: {
                      longitude: message.coordinatesDTO.longitude,
                      latitude: message.coordinatesDTO.latitude,
                      init:
                        car.coordinates?.init ||
                        (() => console.log("Coordinates init called")),
                      toJSON:
                        car.coordinates?.toJSON ||
                        (() => ({
                          longitude: message.coordinatesDTO.longitude,
                          latitude: message.coordinatesDTO.latitude,
                        })),
                    },
                    init:
                      car.init || (() => console.log("Vehicle init called")),
                    toJSON: car.toJSON || (() => ({})),
                  };

                  return updatedCarInfo;
                }
              });
              console.log(message);
            });
          })
          .catch((err) => {
            console.assert(
              connection.state === HubConnectionState.Disconnected
            );
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [isStartCoordinatesUpdate]);

  /*Запрос на получение списка функций по Imei транспорта*/
  async function getFunctionsList(
    uvi: string | undefined,
    id: string | undefined = undefined,
    date: Date | undefined = undefined
  ) {
    let response;
    if (uvi !== undefined && uvi !== null) {
      response = await mapClient.getFunctions(uvi, date);
      if (!response?.succeeded) {
        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.error,
          message: intl.formatMessage({ id: "data_functions_error" }),
        });
        throw response?.message;
      }
    }
    const functionGroup = await mapClient.getFunctionGroups();

    const canIdentifier = await mapClient.getCanIdentifiers(uvi!);

    if (!canIdentifier?.succeeded) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "can_update_error" }),
      });
      throw response?.message;
    }

    setFunctions(response?.data ?? []);
    setFilterFunctions(response?.data ?? []);
    const foundResult =
      response?.data!.find((r) => r.id === (selectedRow?.id ?? id)) ??
      ({} as FunctionDTO);
    setSelectedRow(foundResult);
    setFunctionGroups(functionGroup.data ?? []);
    setCanIdentifiers(canIdentifier?.data ?? []);
  }

  /*Запрос на получение транспорта по id*/
  async function getTransportById(id: string) {
    const response = await mapClient.getVehicleById(id);
    setActiveTransport(response.data!);
    setCarInfo(
      carInfo.map((t) => {
        if (t.id === response.data!.id!) {
          return response.data!;
        }
        return t;
      })
    );
    setSearchTransport(
      searchTransport.map((t) => {
        if (t.id === response.data!.id!) {
          return response.data!;
        }
        return t;
      })
    );
    if (!response?.succeeded) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "transport_error_byId" }),
      });
      throw response?.message;
    }
  }
  async function onChooseTransport(id: string) {
    if (!showTable && !showModuleSettings) {
      const selectedTransport = carInfo.find((car) => car.id === id)!;
      setActiveTransport({ ...selectedTransport } as VehicleDTO);
      try {
        await getFunctionsList(selectedTransport.uvi);
      } catch {
        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.error,
          message: intl.formatMessage({ id: "data_functions_error" }),
        });
      }
    } else {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "error_clickTransport" }),
      });
    }
  }
  /*Запрос на получение модуля*/
  async function getModule(uvi: string) {
    try {
      const response = await mapClient.getModule(uvi);
      setModule(response.data);
    } catch {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "getModuleError" }),
      });
    }
  }
  /*Запрос на получение истории подключений*/
  async function getLogModule(uvi: string) {
    try {
      const response = await mapClient.getModuleConnections(uvi);
      setLogModule(response.data ?? []);
    } catch {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "getLogModuleError" }),
      });
    }
  }
  function onCloseModalActions() {
    onShowCreateTransport(false);
    onClickButtonAddNewTransport(false);
  }
  function onShowCreateTransport(showCreateTransport: boolean) {
    setShowCreateTransport(showCreateTransport);
  }
  function onClickButtonAddNewTransport(transportActiveClick: boolean) {
    setActiveClick(transportActiveClick);
  }
  return (
    <div className="transportMain grid">
      <div className={props.showTransportList ? "z-10" : "-z-10 "}>
        {/*Список транспорта*/}
        {props.showTransportList ? (
          <TransportList
            carInfo={carInfo}
            onShowTransportList={props.onShowTransportList}
            setMapCenter={setMapCenter}
            onChooseTransport={onChooseTransport}
            onShowTransportEditorList={props.onShowTransportEditorList}
            onShowCreateTransport={onShowCreateTransport}
            onClickButtonAddNewTransport={onClickButtonAddNewTransport}
            transportActiveClick={transportActiveClick}
            activeTransport={activeTransport!}
            getTransport={getTransport}
            searchTransport={searchTransport}
            setSearchTransport={setSearchTransport}
            showNotification={props.showNotification}
            onChangeNotificationState={props.onChangeNotificationState}
          />
        ) : null}
      </div>
      {/*Модальное окно Создание транспорта*/}
      <div className="mapContainer">
        {showCreateTransport ? (
          <div>
            <CreateTransport
              onShowCreateTransport={onShowCreateTransport}
              onClickButtonAddNewTransport={onClickButtonAddNewTransport}
              getTransport={getTransport}
              onChangeNotificationState={props.onChangeNotificationState}
              onCloseModalActions={onCloseModalActions}
              showNotification={props.showNotification}
            />
          </div>
        ) : null}
        {/*Редактирование выбранного транспорта*/}
        {props.showTransportEditor ? (
          <TransportEdit
            carInfo={carInfo}
            coordinates={mapCenter}
            functions={functions}
            filterFunctions={filterFunctions}
            showNotification={props.showNotification}
            onChangeNotificationState={props.onChangeNotificationState}
            activeTransport={activeTransport!}
            setActiveTransport={setActiveTransport}
            onShowTransportEditorList={props.onShowTransportEditorList}
            typeTransport={typeTransport}
            setFilterFunctions={setFilterFunctions}
            getFunctionsList={getFunctionsList}
            getTransport={getTransport}
            getTransportById={getTransportById}
            functionGroups={functionGroups}
            setFunctionGroups={setFunctionGroups}
            setShowTable={setShowTable}
            showTable={showTable}
            statusFunctions={statusFunctions}
            criteriaParameters={criteriaParameters}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            canIdentifiers={canIdentifiers}
            setCanIdentifiers={setCanIdentifiers}
            getModule={getModule}
            module={module}
            setModule={setModule}
            showModuleSettings={showModuleSettings}
            setShowModuleSettings={setShowModuleSettings}
            getLogModule={getLogModule}
            logModule={logModule}
          />
        ) : null}
        {/*Карта*/}
        {!props.showTransportEditor ? (
          <CustomMapContainer carInfo={carInfo} coordinates={mapCenter} />
        ) : null}
      </div>
    </div>
  );
}
export default Transport;
