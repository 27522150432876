import React, { useEffect, useMemo, useState } from "react";
import "./CreateEditUser.scss";
import Select from "react-select";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { RoleDTO, UserDTO } from "../../../api/auth/apiClient";
import { mapClient } from "../../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../../Notification/notificationTypes";
import { optionsDataGender } from "./gender";
import { customStyles } from "./styleSelector";
import ModalNotification from "../../../components/CreateTransport/Modal_notification/ModalNotification";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import { useIntl } from "react-intl";
import Notification from "../../Notification/Notification";
setDefaultLocale("ru");

type CreateEditUserProps = {
  setShowCreateUser: (showCreateUser: boolean) => void;
  getUsers: () => void;
  allRoles: Array<RoleDTO>;
  selectedUser: UserDTO;
  showNotification: NotificationState;
  onChangeNotificationState: (state: NotificationState) => void;
};
function CreateEditUser(props: CreateEditUserProps) {
  const [showModalNotification, setShowModalNotification] = useState(false);
  const intl = useIntl();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<UserDTO>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return props.selectedUser;
    }, [props]),
  });
  useEffect(() => {
    reset(props.selectedUser);
  }, [props.selectedUser, reset]);

  async function createUser(data: UserDTO) {
    let response;
    try {
      response = await mapClient.createUser(data);
      if (response.succeeded) {
        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.info,
          message: intl.formatMessage({ id: "createUser_success" }),
        });

        props.setShowCreateUser(false);
      } else throw response.errors;
    } catch (error: any) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({ id: "createUser_error" })}: ${
          error?.errors ? error.errors[0] : "Unknown error"
        }`,
      });
    }
    await props.getUsers();
  }

  async function updateUser(data: UserDTO) {
    let response;
    try {
      response = await mapClient.updateUser(data);
      if (response.succeeded) {
        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.info,
          message: intl.formatMessage({ id: "updateUser_success" }),
        });

        props.setShowCreateUser(false);
      } else throw response.errors;
    } catch (error: any) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({ id: "updateUser_error" })}: ${
          error?.errors ? error.errors[0] : "Unknown error"
        }`,
      });
    }
    await props.getUsers();
  }
  const onSubmit: SubmitHandler<UserDTO> = async (data) => {
    if (data.id !== undefined) {
      await updateUser(data);
    } else {
      await createUser(data);
    }
  };

  return (
    <>
      {showModalNotification ? (
        <div>
          <ModalNotification
            modalQuestion={
              props.selectedUser.id
                ? intl.formatMessage({ id: "user_update_question" })
                : intl.formatMessage({ id: "user_create_question" })
            }
            onYesModalActions={() => setShowModalNotification(false)}
            onNoModalActions={() => props.setShowCreateUser(false)}
          />
        </div>
      ) : null}
      <div className="overlay">
        <div className="createContainer">
          <div className="createForm">
            <div className="createHeader flex justify-between items-center">
              <h3>
                {props.selectedUser.id
                  ? intl.formatMessage({ id: "update_user_title" })
                  : intl.formatMessage({ id: "create_new_user" })}
              </h3>
              <img
                src="/image/btn-remove/btn-remove.svg"
                alt="Escape"
                onClick={() => setShowModalNotification(true)}
              />
            </div>
            <div className="createBlock mt-5">
              <form
                autoComplete="off"
                onSubmit={handleSubmit(onSubmit)}
                className="createBlockForm"
              >
                <div className="createBlockColumns">
                  <div className="createBlockFirstColumn mr-8">
                    <div className="userName mb-2">
                      <label htmlFor="userName">
                        {intl.formatMessage({ id: "userName" })}
                      </label>
                      <input
                        {...register("userName", {
                          required: intl.formatMessage({
                            id: "input_required",
                          }),
                        })}
                        type="text"
                        id="userName"
                        placeholder={intl.formatMessage({
                          id: "placeholder_userName",
                        })}
                      />
                      <div>
                        {errors?.userName && (
                          <p>{errors?.userName?.message || "Errors"}</p>
                        )}
                      </div>
                    </div>

                    <div className="email mb-2">
                      <label htmlFor="email">
                        {intl.formatMessage({ id: "email" })}
                      </label>
                      <input
                        {...register("email", {
                          required: intl.formatMessage({
                            id: "input_required",
                          }),
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: intl.formatMessage({
                              id: "validate_email",
                            }),
                          },
                        })}
                        type="email"
                        id="email"
                        placeholder={intl.formatMessage({
                          id: "placeholder_email",
                        })}
                      />
                      <div>
                        {errors?.email && (
                          <p>{errors?.email?.message || "Errors"}</p>
                        )}
                      </div>
                    </div>
                    <div className="login mb-2">
                      <label htmlFor="login">
                        {intl.formatMessage({ id: "login_user" })}
                      </label>
                      <input
                        {...register("login")}
                        type="text"
                        id="login"
                        placeholder={intl.formatMessage({
                          id: "placeholder_login_user",
                        })}
                      />
                    </div>
                    <div className="password mb-2">
                      <label htmlFor="password">
                        {intl.formatMessage({ id: "password_user" })}
                      </label>
                      <input
                        {...register("password", {
                          required: intl.formatMessage({
                            id: "input_required",
                          }),
                          pattern: {
                            value:
                              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+_]).{6,30}$/,
                            message: intl.formatMessage({
                              id: "validate_password",
                            }),
                          },
                        })}
                        type="password"
                        id="password"
                        placeholder={intl.formatMessage({
                          id: "placeholder_password_user",
                        })}
                      />
                      <div>
                        {errors?.password && (
                          <p>{errors?.password?.message || "Errors"}</p>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-wrap items-center">
                      <div>
                        <label htmlFor="gender" className="mr-2">
                          {intl.formatMessage({ id: "users_roles" })}
                        </label>
                      </div>
                      <div className="roles">
                        <Controller
                          name={"roles"}
                          control={control}
                          render={({ field: { value, name, onChange } }) => (
                            <Select
                              name={name}
                              isMulti={true}
                              isSearchable={false}
                              options={props.allRoles.map((role) => ({
                                value: role,
                                label: role.name!,
                              }))}
                              styles={customStyles}
                              value={props.allRoles
                                .filter((role) => value?.includes(role))
                                .map((role) => ({
                                  value: role,
                                  label: role.name!,
                                }))}
                              onChange={(values) =>
                                onChange(
                                  values.map((v) => v.value) ?? undefined
                                )
                              }
                              placeholder={intl.formatMessage({
                                id: "placeholder_users_roles",
                              })}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="createBlockSecondColumn">
                    <div className="firstName mb-2">
                      <label htmlFor="firstName">
                        {intl.formatMessage({ id: "firstName" })}
                      </label>
                      <input
                        {...register("firstName")}
                        type="text"
                        id="firstName"
                        placeholder={intl.formatMessage({
                          id: "placeholder_firstName",
                        })}
                      />
                    </div>
                    <div className="secondName mb-2">
                      <label htmlFor="secondName">
                        {intl.formatMessage({ id: "secondName" })}
                      </label>
                      <input
                        {...register("secondName")}
                        type="text"
                        id="secondName"
                        placeholder={intl.formatMessage({
                          id: "placeholder_secondName",
                        })}
                      />
                    </div>
                    <div className="dateBirth mb-2">
                      <label htmlFor="dateBirth">
                        {intl.formatMessage({ id: "dateBirth" })}
                      </label>
                      <Controller
                        name={"dateBirth"}
                        control={control}
                        render={({ field: { value, name, onChange } }) => (
                          <DatePicker
                            locale="ru"
                            name={name}
                            selected={value}
                            isClearable
                            calendarStartDay={3}
                            onChange={(value) => onChange(value ?? undefined)}
                            peekNextMonth
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            dateFormat="dd/MM/yyyy"
                            placeholderText={intl.formatMessage({
                              id: "placeholder_dateBirth",
                            })}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            disabledKeyboardNavigation
                          />
                        )}
                      />
                    </div>
                    <div className="flex flex-wrap items-center">
                      <div>
                        <label htmlFor="gender" className="mr-2">
                          {intl.formatMessage({ id: "user_gender" })}
                        </label>
                      </div>
                      <div className="gender">
                        <Controller
                          name={"gender"}
                          control={control}
                          render={({ field: { value, name, onChange } }) => (
                            <Select
                              name={name}
                              options={optionsDataGender}
                              styles={customStyles}
                              isSearchable={false}
                              isClearable={false}
                              value={optionsDataGender.find(
                                (option) => option.value === value ?? undefined
                              )}
                              onChange={(value) => onChange(value?.value)}
                              placeholder={intl.formatMessage({
                                id: "placeholder_user_gender",
                              })}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="createButtonUser">
                  <button type="submit" className="btn_save mr-8">
                    <span>{intl.formatMessage({ id: "create" })}</span>
                  </button>
                  <button
                    type="reset"
                    className="cancel"
                    onClick={() => setShowModalNotification(true)}
                  >
                    <span>{intl.formatMessage({ id: "cancel" })}</span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Notification
          showNotification={props.showNotification}
          onChangeNotificationState={props.onChangeNotificationState}
        />
      </div>
    </>
  );
}

export default CreateEditUser;
