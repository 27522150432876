import React, { useEffect, useMemo, useState } from "react";
import "./CanIdentifier.scss";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as InputSearch } from "./svg/InputSearch.svg";
import { ReactComponent as CanConfirmedSuccess } from "./svg/canSuccess.svg";
import { ReactComponent as DeleteCan } from "./svg/btn-remove_modal.svg";
import { ReactComponent as SaveCan } from "./svg/Save.svg";
import { ReactComponent as EditCan } from "./svg/btnEditor.svg";
import { ReactComponent as AddNewRecord } from "./svg/Add.svg";
import { ReactComponent as CanConfirmedError } from "./svg/canError.svg";
import { ReactComponent as AddNewCan } from "./svg/add_new_can.svg";
import { ReactComponent as UpdateCan } from "./svg/updateCan.svg";
import DataTable, { TableColumn } from "react-data-table-component";
import { CanIdentifierDTO, FunctionDTO } from "../../../../api/auth/apiClient";
import Select from "react-select";
import { Checkbox, Hint } from "@skbkontur/react-ui";
import { selectStyles } from "./styleSelector";
import { DataSendingPeriod, optionsCanType } from "../data";
import { mapClient } from "../../../../api/auth/AxiosInstanse";
import {
  NotificationState,
  NotificationType,
} from "../../../Notification/notificationTypes";
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import ModalNotification from "../../../CreateTransport/Modal_notification/ModalNotification";
import { customStylesCan } from "./stylesCan";

type CanIdentifierProps = {
  canIdentifiers: Array<CanIdentifierDTO>;
  selectedRow: FunctionDTO;
  showNotification: NotificationState;
  onChangeNotificationState: (state: NotificationState) => void;
  setCanIdentifiers: (canIdentifiers: CanIdentifierDTO[]) => void;
  setShowModalCan: (isShow: boolean) => void;
  getFunctionsList: (uvi: string, id: string | undefined) => void;
};

type ArrayCanIdentifierDTO = {
  array: CanIdentifierDTO[];
};

const logCanId = (canId: string) => {
  console.log(`CAN ID added or displayed: ${canId}`);
};

function CanIdentifier(props: CanIdentifierProps) {
  const intl = useIntl();
  const [standardCount, setStandardCount] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [showModalDeleteCan, setShowModalDeleteCan] = useState(false);
  const [showModalCancelAction, setShowModalCancelAction] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<ArrayCanIdentifierDTO>({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return { array: props.canIdentifiers };
    }, [props]),
  });

  useEffect(() => {
    console.log("Received canIdentifiers:", props.canIdentifiers);
    if (props.canIdentifiers) {
      const updatedIdentifiers = props.canIdentifiers.map((item) => ({
        ...item,
        canId: item.canId.startsWith("0x") ? item.canId : `0x${item.canId}`,
      }));
      console.log("Updated Identifiers:", updatedIdentifiers);
      reset({ array: updatedIdentifiers });
    }
  }, [props.canIdentifiers, reset]);

  useEffect(() => {
    if (props.selectedRow.uvi) {
      setCurrentUvi(props.selectedRow.uvi);
    }
  }, [props.selectedRow.uvi]);

  const { fields, append, remove } = useFieldArray({ control, name: "array" });
  const [canIdentifierToDelete, setCanIdentifierToDelete] = useState<number>();
  const [canIdentifierCanIndexToDelete, setCanIdentifierCanIndexToDelete] =
    useState<number>();
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [isApplyButtonEnabled, setIsApplyButtonEnabled] = useState(false);
  const [isApplied, setIsApplied] = useState(true);
  const [isEditing, setIsEditing] = useState<{ [key: number]: boolean }>({});
  const [currentUvi, setCurrentUvi] = useState(props.selectedRow.uvi);

  useEffect(() => {
    const subscription = watch((value) => {
      const hasProtocolError = value.array
        ? value.array.some((item) => item && !item.protocol)
        : false;

      const hasPeriodError = value.array
        ? value.array.some((item) => !item?.period)
        : false;

      const hasCanIdError = value.array
        ? value.array.some((item) => !item?.canId)
        : false;

      const hasDuplicateCanIdError = value.array
        ? value.array.some((_, index) => {
            const errorMessage = errors?.array?.[index]?.canId?.message;
            return (
              errorMessage === intl.formatMessage({ id: "error_message_id" })
            );
          })
        : false;

      const hasValidationErrors =
        hasProtocolError ||
        hasPeriodError ||
        hasCanIdError ||
        hasDuplicateCanIdError;

      if (hasValidationErrors) {
        setIsSendButtonDisabled(true);
        setIsApplyButtonEnabled(false);
      } else {
        setIsSendButtonDisabled(false);
        setIsApplyButtonEnabled(true);
      }

      const count = value.array
        ? value.array.filter((item) => item?.protocol === 1).length
        : 0;

      setStandardCount(count);

      if (count > 2) {
        setError(intl.formatMessage({ id: "error_message_protocol" }));
      } else {
        setError(null);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, errors, intl]);

  useEffect(() => {
    if (fields.length === 0) {
      setIsSendButtonDisabled(false);
    } else {
      setIsSendButtonDisabled(!isValid || error !== null);
    }
  }, [isValid, error, fields.length]);

  useEffect(() => {
    console.log("currentUvi updated:", currentUvi);
    console.log("isEditing state updated:", isEditing);
    console.log("editingIndex updated:", editingIndex);
    console.log("isApplyButtonEnabled updated:", isApplyButtonEnabled);
    console.log("DataTable fields:", fields);
    console.log("Current selectedRow (updated):", props.selectedRow);
  }, [
    currentUvi,
    isEditing,
    editingIndex,
    isApplyButtonEnabled,
    fields,
    props.selectedRow,
  ]);

  const startEditing = (index: number) => {
    console.log("Starting editing for index:", index);

    setIsEditing({ [index]: true });

    setIsApplied(false);
    setEditingIndex(index);
    setIsApplyButtonEnabled(true);
  };

  const onApply = () => {
    console.log("Applying changes");

    setIsApplied(true);
    setIsApplyButtonEnabled(false);

    if (editingIndex !== null) {
      setIsEditing((prevState) => ({ ...prevState, [editingIndex]: false }));
    }
    setEditingIndex(null);
  };

  const onSubmit: SubmitHandler<ArrayCanIdentifierDTO> = async (data) => {
    const updatedData = {
      array: data.array.map((item) => {
        return new CanIdentifierDTO({
          ...item,
          canId: item.canId.startsWith("0x") ? item.canId : `0x${item.canId}`,
        });
      }),
    };

    console.log("Submitting Data:", updatedData);
    console.log("Current selectedRow (before submission):", currentUvi);

    updatedData.array.forEach((item) => {
      if (!item.uvi) {
        console.warn("Missing UVI for item:", item);
      }
    });

    if (!isApplied) {
      onApply();
    }

    console.log("Selected row uvi:", props.selectedRow.uvi);

    if (!currentUvi) {
      console.warn("Missing currentUvi, cannot proceed with submission.");
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "missing_uvi_error_message" }),
      });
      return;
    }

    try {
      const result = await mapClient.updateCanIdentifiers(
        currentUvi,
        updatedData.array
      );

      console.log("Server response:", result);

      if (result?.succeeded) {
        console.info("Update succeeded:", result);

        props.onChangeNotificationState({
          isShow: true,
          type: NotificationType.info,
          message: intl.formatMessage({ id: "changes_can_success" }),
        });
        await props.getFunctionsList(currentUvi, undefined);
        reset({ array: data.array });
      } else {
        throw new Error(result?.message || "Failed to update");
      }
    } catch (error: any) {
      console.error("Error during request:", error);
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: `${intl.formatMessage({
          id: "changes_can_error",
        })}: ${error?.errors?.[0] || error.message || "Unknown error"}`,
      });
    }
  };

  const addNewRecord = () => {
    console.log("Adding new record with currentUvi:", currentUvi);

    if (!currentUvi) {
      props.onChangeNotificationState({
        isShow: true,
        type: NotificationType.error,
        message: intl.formatMessage({ id: "missing_uvi_error_message" }),
      });
      return;
    }

    const newRecord = new CanIdentifierDTO({
      id: undefined,
      uvi: currentUvi,
      index: undefined,
      canId: "",
      period: undefined,
      protocol: optionsCanType[0].value,
      extended: false,
      configurationConfirmed: false,
    });

    append(newRecord);
    setEditingIndex(fields.length);
    setIsEditing((prevState) => ({ ...prevState, [fields.length]: true }));
    setIsApplyButtonEnabled(true);
    console.log("New record added:", newRecord);
  };

  function removeRecord(arrayIndex: number, index: number | undefined) {
    remove(arrayIndex);
    setIsApplyButtonEnabled(true);
  }

  function onShowDeleteCan(arrayIndex: number, index: number | undefined) {
    setCanIdentifierToDelete(arrayIndex);
    setCanIdentifierCanIndexToDelete(index);
    setShowModalDeleteCan(true);
  }

  useEffect(() => {
    props.canIdentifiers.forEach((identifier) => logCanId(identifier.canId));
  }, [props.canIdentifiers]);

  const columns: TableColumn<CanIdentifierDTO>[] = [
    {
      name: intl.formatMessage({ id: "canId" }),
      cell: (can, index) => (
        <div className="flex-wrap">
          <Controller
            name={`array.${index}.canId`}
            control={control}
            rules={{
              required: intl.formatMessage({ id: "canId_validation" }),
              pattern: {
                value: /^0x[0-9a-fA-F]+$/,
                message: intl.formatMessage({ id: "canId_validation" }),
              },
              validate: (value) => {
                if (fields.length === 0) return true;

                if (!value) {
                  return intl.formatMessage({ id: "canId_validation" });
                }
                if (!/^0x[0-9a-fA-F]+$/.test(value)) {
                  return intl.formatMessage({ id: "canId_validation" });
                }

                const allIds = watch("array").map((item) => item.canId);
                const duplicateCount = allIds.filter(
                  (id, idx) => id === value && idx !== index
                ).length;

                if (duplicateCount > 0) {
                  setIsApplyButtonEnabled(false);
                  setIsSendButtonDisabled(true);
                  return intl.formatMessage({ id: "error_message_id" });
                }

                setIsApplyButtonEnabled(true);
                setIsSendButtonDisabled(false);

                const maxValueExtendedTrue = 0x1fffffff;
                const maxValueExtendedFalse = 0x7ff;
                const extended = getValues(`array.${index}.extended`);
                const numericValue = parseInt(value.replace(/^0x/, ""), 16);

                if (extended === true) {
                  if (value.length !== 10) {
                    return intl.formatMessage({
                      id: "canId_validation_extended_true",
                    });
                  }
                  if (numericValue > maxValueExtendedTrue) {
                    return intl.formatMessage({
                      id: "canId_validation_extended_true_maxValue",
                    });
                  }
                }

                if (extended === false) {
                  if (value.length !== 5) {
                    return intl.formatMessage({
                      id: "canId_validation_extended_false",
                    });
                  }
                  if (numericValue > maxValueExtendedFalse) {
                    return intl.formatMessage({
                      id: "canId_validation_extended_false_maxValue",
                    });
                  }
                }

                return true;
              },
            }}
            render={({ field }) => {
              console.log(
                "Rendering canId input for index:",
                index,
                "with value:",
                field.value
              );
              return (
                <input
                  {...field}
                  type="text"
                  id="canId"
                  placeholder="CAN ID"
                  className={`canId ${
                    isEditing[index] ? "text-normal" : "text-gray-400"
                  }`}
                  style={{
                    backgroundColor: "transparent",
                    border: "none",
                    boxShadow: "none",
                  }}
                  disabled={
                    !isEditing[index] ||
                    (editingIndex !== index &&
                      (!!can.canId || getValues(`array.${index}.period`) === 0))
                  }
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (!inputValue.startsWith("0x")) {
                      field.onChange(`0x${inputValue.replace(/^0x/, "")}`);
                    } else {
                      field.onChange(inputValue);
                    }
                  }}
                />
              );
            }}
          />
          <div className="mt-2">
            {(errors?.array ?? [])[index]?.canId ||
            !watch(`array.${index}.canId`) ? (
              <p className="text-red-500">
                {errors?.array?.[index]?.canId?.message ||
                  intl.formatMessage({ id: "input_required" })}
              </p>
            ) : null}
          </div>
        </div>
      ),
      grow: 2,
    },
    {
      name: intl.formatMessage({ id: "protocol" }),
      cell: (can, index) => (
        <div className="flex-wrap">
          <Controller
            name={`array.${index}.protocol`}
            control={control}
            rules={{
              validate: (value) => {
                if (!value) {
                  return intl.formatMessage({ id: "can_input_required" });
                }
                return true;
              },
            }}
            render={({
              field: { onChange, name, value },
              fieldState: { error },
            }) => (
              <select
                name={name}
                onChange={(e) => onChange(Number(e.target.value))}
                value={value || ""}
                className={`canId ${error ? "text-red-500" : ""} ${
                  isEditing[index] ? "active-style" : "disabled-style"
                }`}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                  padding: "5px",
                }}
                disabled={!isEditing[index]}
              >
                {optionsCanType.map((canOption) => (
                  <option key={canOption.value} value={canOption.value}>
                    {canOption.label}
                  </option>
                ))}
              </select>
            )}
          />
          <div className="mt-2">
            {(errors?.array ?? [])[index]?.protocol && (
              <p className="text-red-500">
                {(errors?.array ?? [])[index]?.protocol?.message ||
                  intl.formatMessage({ id: "can_input_required" })}
              </p>
            )}
          </div>
        </div>
      ),
      grow: 2,
    },
    {
      name: intl.formatMessage({ id: "can_period" }),
      cell: (can, index) => (
        <div className="flex-wrap">
          <input
            {...register(`array.${index}.period`, {
              required: intl.formatMessage({ id: "can_input_required" }),
              pattern: {
                value: /^\d+([.]\d)?$/,
                message: intl.formatMessage({ id: "can_period_validation" }),
              },
            })}
            className={`canId ${errors?.array?.[index]?.period ? "text-red-500" : ""} ${
              isEditing[index] ? "active-style" : "disabled-style"
            }`}
            id={"period"}
            type={"text"}
            placeholder={intl.formatMessage({
              id: "placeholder_choose_period",
            })}
            style={{
              backgroundColor: "transparent",
              border: "none",
              boxShadow: "none",
              height: "50px",
            }}
            disabled={!isEditing[index]}
          />
          <div className="mt-2">
            {(errors?.array ?? [])[index]?.period ||
            !watch(`array.${index}.period`) ? (
              <p className="text-red-500">
                {errors?.array?.[index]?.period?.message ||
                  intl.formatMessage({ id: "input_required" })}
              </p>
            ) : null}
          </div>{" "}
        </div>
      ),
      grow: 2,
    },
    {
      name: intl.formatMessage({ id: "can_type_extended" }),
      cell: (can, index) => (
        <div className="flex-wrap">
          <Controller
            name={`array.${index}.extended`}
            control={control}
            render={({ field: { onChange, name, value } }) => (
              <select
                name={name}
                disabled={
                  !isEditing[index] ||
                  (editingIndex !== index &&
                    (!!can.canId || getValues(`array.${index}.period`) === 0))
                }
                onChange={(e) => onChange(e.target.value === "true")}
                value={value ? "true" : "false"}
                className={`canId ${isEditing[index] ? "text-normal" : "text-gray-400"}`}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  boxShadow: "none",
                  padding: "5px",
                }}
              >
                <option value="false">11 - bit (Standard)</option>
                <option value="true">29 - bit (Extended)</option>
              </select>
            )}
          />
          <div className="mt-2">
            {(errors?.array ?? [])[index]?.extended && (
              <p>
                {(errors?.array ?? [])[index]?.extended?.message || "Errors!!!"}
              </p>
            )}
          </div>
        </div>
      ),
      center: true,
    },
    {
      name: intl.formatMessage({ id: "can_action" }),
      cell: (can, arrayIndex) => (
        <div className="flex justify-center items-center mb-2.5">
          <DeleteCan
            width="17"
            height="17"
            onClick={() => onShowDeleteCan(arrayIndex, can.index)}
          />
          {editingIndex !== arrayIndex && (
            <EditCan
              className="ml-2"
              width="17"
              height="17"
              onClick={() => startEditing(arrayIndex)}
            />
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      {showModalDeleteCan ? (
        <div>
          <ModalNotification
            onYesModalActions={() => setShowModalDeleteCan(false)}
            modalQuestion={intl.formatMessage({
              id: "delete_can_identifier_question",
            })}
            onNoModalActions={() => {
              removeRecord(
                canIdentifierToDelete!,
                canIdentifierCanIndexToDelete
              );
              setShowModalDeleteCan(false);
            }}
          />
        </div>
      ) : null}

      {showModalCancelAction ? (
        <div>
          <ModalNotification
            onYesModalActions={() => setShowModalCancelAction(false)}
            modalQuestion={intl.formatMessage({
              id: "cancel_action_question",
            })}
            onNoModalActions={() => props.setShowModalCan(false)}
          />
        </div>
      ) : null}

      <div className="modal_can">
        <div className="modal_can_container">
          <form onSubmit={handleSubmit(onSubmit)} className="modal_can_form">
            <div className="modal_can_header">
              <div className="modal_can_form_title">
                <h3>
                  <FormattedMessage id="changes_CanId" />
                </h3>
                <img
                  src="/image/btn-remove/btn-remove.svg"
                  alt="Close form"
                  onClick={() => props.setShowModalCan(false)}
                />
              </div>
              {/*<div className="modal_can_form_search mt-3">
                <div className="can_search flex">
                  <div className="searchImageCan">
                    <InputSearch />
                  </div>
                  <input
                    type="text"
                    className="searchInputCan"
                    placeholder={intl.formatMessage({ id: "search_CanId" })}
                  />
                </div>
              </div>*/}
            </div>
            <div className="modal_can_form_body mt-3.5">
              <div className="flex justify-start">
                <button
                  type="button"
                  className="add_new_record"
                  onClick={addNewRecord}
                >
                  <AddNewCan className="ml-2.5" />
                  {/* <AddNewRecord className="mr-1" />*/}
                  <span className="ml-2.5">
                    {intl.formatMessage({ id: "add_can" })}
                  </span>
                </button>
                <div className="ml-5">
                  <button
                    type="button"
                    className="update_can"
                    onClick={() =>
                      props.getFunctionsList(props.selectedRow.uvi, undefined)
                    }
                  >
                    <UpdateCan className="ml-7" />
                    <span className="ml-2">
                      {intl.formatMessage({ id: "update" })}
                    </span>
                  </button>
                </div>
              </div>
              <div className="mt-3.5">
                <DataTable
                  columns={columns}
                  data={fields}
                  noDataComponent={intl.formatMessage({ id: "empty_table" })}
                  fixedHeader
                  striped={false}
                  fixedHeaderScrollHeight="450px"
                  pointerOnHover={true}
                  persistTableHead
                  customStyles={customStylesCan}
                />
              </div>
            </div>
            {error && (
              <div className="error-message text-red-500 mb-2">{error}</div>
            )}
            <div className="modal_can_form_footer mt-5">
              <div className="flex items-center">
                <div>
                  <button
                    type="button"
                    className={`btn ${isApplyButtonEnabled ? "" : "disabled"}`}
                    disabled={!isApplyButtonEnabled}
                    onClick={onApply}
                  >
                    <span className="ml-2">
                      <FormattedMessage id="apply" />
                    </span>
                  </button>
                </div>

                <div className="ml-7 mr-7">
                  <button
                    className="cancel"
                    type="button"
                    onClick={() => setShowModalCancelAction(true)}
                  >
                    <span>
                      <FormattedMessage id="cancel" />
                    </span>
                  </button>
                </div>

                <div>
                  <button
                    type="submit"
                    className={`btn ${isSendButtonDisabled ? "disabled" : ""}`}
                    disabled={isSendButtonDisabled}
                  >
                    <span className="ml-2">
                      <FormattedMessage id="send" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default CanIdentifier;
